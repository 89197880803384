import { configureStore } from "@reduxjs/toolkit";

import LayoutSlice from "./Layoutslice";
import LawyerDetailSlice from "./LawyerDetailSlice";
import LoaderSlice from "./LoaderSlice";

const store = configureStore({
  reducer: {
    layout: LayoutSlice,
    lawyerdetail: LawyerDetailSlice,
    toomanyrequest: LoaderSlice
  },
  devTools: false,
});

export default store;
