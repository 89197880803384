import { createSlice } from "@reduxjs/toolkit";

let flag = false;

const LoaderSlice = createSlice({
  name: "toomanyrequest",
  initialState: flag,

  reducers: {

    setFlag: (state, action) => {
      state.loader = action.payload;
      return state;
    },

  },
});

export const { setFlag } = LoaderSlice.actions;
export default LoaderSlice.reducer;
