import { createSlice } from "@reduxjs/toolkit";
let detail = [];
const LawyerDetailSlice = createSlice({
  name: "lawyerdetail",
  initialState: detail,
  reducers: {
    setDetails: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setDetails } = LawyerDetailSlice.actions;
export default LawyerDetailSlice.reducer;
